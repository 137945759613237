import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { isMobile } from "react-device-detect";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';

import AutoCarousel from "./AutoCarousel";

const useStyles = makeStyles((theme) => ({
  root: {
		padding: theme.spacing(2),
		display: 'flex', 
		flexDirection: 'column',
	},
	contact_title: {
		fontFamily: "Open Sans, Lato, Muli",
		color: '#03234b',
  	letterSpacing: '.015em',
  	fontWeight: 800,
   	fontSize: 'calc(22px + 0.6vw)',
	},
	content_1: {
		fontSize: (isMobile) ? 'calc(12px + 0.5vw)': 'calc(10px + 0.4vw)',
		fontFamily: "Open Sans, Muli, Lato",
  	color: '#03234b',
  	textAlign: (isMobile) ? 'left' : 'justify',
		padding: (isMobile) ? '4% 0 0 0'  : '2% 0 0 0',
	},
	list_root: {
		margin: (isMobile) ? '5% 0 0 0' : '5% 0% 0% 0%',
	},
	list_row: {
		width: '100%',
		display: 'flex', 
		flexDirection: 'row',
		flexGrow:1,
  	flexShrink:1,
  	justifyContent: 'space-between',
	},
	list_column: {
		width: '100%',
		display: 'flex', 
		flexDirection: 'column',
		flexGrow:1,
  	flexShrink:1,
  	justifyContent: 'space-between',
	},
	card: {
		width: (isMobile) ? '92%' : '80%',
		margin: (isMobile) ? '2% 0 2% 0' : '0.5%',
		minHeight:  (isMobile)?  'calc(100px + 4vw)' : 'calc(60px + 2vw)',
		background: '#fff',
		display:'flex',
    flexDirection: 'column',
    alignItems: 'center',
	},
	sub_card: {
		padding: (isMobile)? '5%' : '5% 3% 3% 3%',
	},
	icon: {
		height:  (isMobile)?  'calc(100px + 8vw)' : 'calc(120px + 4vw)',
		width: '100%', 
		backgroundSize: '100% 100%', 
 		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
	},
	list_content_1: {
		padding: (isMobile) ? '4% 0 0 0'  : '4% 0 0 0', 
		width: '100%',
		color:'#03234b', 
		fontFamily: "Open Sans, Muli, Lato",
		fontWeight: 800,
		letterSpacing: '0.1em',
		fontSize: (isMobile) ? 'calc(16px + 0.6vw)': 'calc(15px + 0.3vw)',
		textTransform: 'uppercase',
		display: 'flex',
		justifyContent: 'center',
		textAlign: 'center'
	},
	banner: {
		paddingTop: (isMobile) ? '4%' : '2%',
	},
	list_content_2: {
		padding: (isMobile) ? '4% 0 0 0'  : '2% 0 0 0', 
		fontSize: (isMobile) ? 'calc(12px + 0.5vw)': 'calc(10px + 0.4vw)',
		fontFamily: "Open Sans, Muli, Lato",
  	color: '#03234b',
		display: 'flex',
		justifyContent: 'center',
		textAlign: 'center',
		opacity: 0.95,
	},

}));

const items = [
	{img: 'embedded/embedded3.jpg'},
	{img: 'embedded/embedded2.jpg'},
	{img: 'embedded/embedded1.jpg'},
];

const specialize= [
	[
		{
			title: 'Microcontroller', 
			img: 'embedded/microcontroller.jpg', 
			content: 'ARM, PIC, AVR',
			isfull: false,
		},
		{	
			title: 'RTOS', 
			img: 'embedded/rtos.jpg', 
			content: 'FreeRTOS, TI-RTOS, Keil RTX, Linux',
			isfull: true,
		},
		{ 
			title: 'Driver', 
			img: 'embedded/device_driver.jpg', 
			content: 'All kind of Analog and Digital Sensor, External device',
			isfull: true,
		},
	],
	[
		{
			title: 'Networking Protocol', 
			img: 'embedded/network_protocol.jpg', 
			content: 'TCP/IP, UDP, HTTP, SMTP, FTP',
			isfull: false,
		},
		{
			title: 'Wired comm. Protocol', 
			img: 'embedded/wired_comm.jpg', 
			content: '4-20mA, I2C, RS232, RS485, UART, USB, SDIO, Ethernet',
			isfull: false,
		},
		{
			title: 'Wireless comm. Protocol', 
			img: 'embedded/wireless_comm.jpeg', 
			content: 
				"WiFi, Bluetooth(BLE5 Mesh), Thread, ZigBee, LoRa, RF, UWB, GSM/GPRS",
			isfull: true,
		},
	],
	[ 
		{
			title: 'Machine Learning', 
			img: 'embedded/machine_learning.jpg', 
			content: '',
			isfull: false,
		},
		{
			title: 'Reverse Engineering', 
			img: 'embedded/reverse_engineering.jpg', 
			content: '',
			isfull: false,
		},
		{
			title: '', 
			img: '', 
			content: '',
			isfull: false,
		},	
	]
]; 

function Embedded() {
	const classes = useStyles();
	window.scrollTo(0, 0);
	
  return (
    <div id='orbidx-embedded' className={classes.root}>
    	<div className={classes.contact_title}>
				EMBEDDED SOFTWARE DEVELOPMENT
			</div>
			<div className={classes.banner}>
				<AutoCarousel items={items} indicators={false}/>
			</div>
			<div className={classes.content_1}>
				We develop scalabe and low-power embedded software for your application
				includes microcontroller or microprocessor. We develop firmware, 
				device driver, UI and Non-UI, RTOS and Non-RTOS embedded application.  
				Orbidx supports embedded software development at every stage of the 
				project workflow.
			</div>
			<div className={classes.content_1}>
				We Specialize in
			</div>
			<div className={classes.list_root}>
			{
				specialize.map((row, index) =>
				<div 
					id={index} 
					className={(isMobile) ? classes.list_column : classes.list_row}
				>
				{
					row.map((element, index) =>  
						<Paper 
							elevation={(element.title == '') ? 0 : 1} 
							id={element.title} 
							className={classes.card}
							style={{minHeight: (element.title != '') ? 
								((isMobile) ?  'calc(100px + 4vw)' : 'calc(60px + 2vw)') : 
								'0px',
								background: (element.title == '') ? 'inherit' : '#fff',
							}}
						>
							{ 
								(element.title != '') ?
									<div>
										<div 
											className={classes.icon} 
											style={{
												backgroundImage: 
													`url(${require(`./assets/${element.img}`)})`,
												backgroundSize: (element.isfull) ? '100% 100%' : '85% 85%', 
											}}
										/>
										<div className={classes.sub_card}>
											<div className={classes.list_content_1}>
												{element.title}
											</div>
											<div className={classes.list_content_2}>
												{element.content}
											</div>
										</div>
									</div>
									:
									""
							}
						</Paper>
					)
				}
				</div>
				)
			}
			</div>
    </div>
  );
}

export default Embedded;
