import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { isMobile } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
  root: {
  	fontFamily: "Exo, Lato, sans-serif",
  	color: '#03234b',
  	display: 'flex',
  	flexDirection: 'row',
  	fontSize:  (isMobile) ? '10px' : '12px',
  	fontWeight: 600,
  	letterSpacing: '.18em',
  	flexGrow:1,
  	flexShrink:1,
  	marginTop: 0,
  	justifyContent: 'space-between',
  	paddingBottom: '5px',
  },
  link: {
  	textDecoration: 'none', 
  	color: '#03234b',
  }
}));

function Footer(props) {
	const classes = useStyles();

  return (
    <div id='orbidx-footer' className={classes.root}>
    	<a className={classes.link}> © ORBIDX 2019 </a>
    	<a href="email:info@orbidx.com" className={classes.link}>
    		INFO@ORBIDX.COM
    	</a>
    	{
    		(window.location.pathname == '/contact-us') ? 
    			<a href="tel:04224519206" className={classes.link}> 0422-4519206 </a>
    			:
    			<a href="/contact-us" onClick={props.forceUpdate} className={classes.link}> CONTACT US </a>
    	}
    </div>
  );
}

export default Footer;
