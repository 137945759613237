import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { isMobile } from "react-device-detect";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import AutoCarousel from "./AutoCarousel";

const useStyles = makeStyles((theme) => ({
  root: {
		padding: theme.spacing(2),
		display: 'flex', 
		flexDirection: 'column',
	},
	product_label: {
    width: theme.spacing((isMobile) ? 3 : 8), 
		minHeight: (isMobile) ? '120px' : '250px', 
		background:'#07ded2', 
		position:'fixed',
		top: (isMobile) ? '30%' : '35%',
		left: 0,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		fontFamily: "Open Sans, Lato, Muli",
		justifyContent: 'space-evenly',
		writingMode: 'vertical-lr',
		textOrientation: 'upright',
   	fontSize: (isMobile) ?  'calc(14px + 0.5vw)' : 'calc(18px + 0.5vw)',
   	fontWeight: 800,
   	color: '#f8f9fb',
  },
	wallpaper: {
		width : '100%', 
		height: (isMobile) ? '150px' : '400px',  
		backgroundSize: '100% 100%', 
 		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
	},
	contact_title: {
		fontFamily: "Open Sans, Lato, Muli",
		color: '#03234b',
  	letterSpacing: '.015em',
  	fontWeight: 800,
   	fontSize: 'calc(30px + 0.6vw)',
		marginBottom: '1%',
	},
	content_1: {
		padding: (isMobile) ? '4% 0 0 0'  : '2% 0 0 0', 
		color:'#03234b', 
		fontFamily: "Open Sans, Muli, Lato",
		fontWeight: 600,
		fontSize: (isMobile) ? 'calc(11px + 0.5vw)': 'calc(10px + 0.4vw)',
	},
	features: {
		padding: (isMobile) ? '4% 0% 4% 0%' : '2% 0% 2% 0%',
		fontFamily: "Open Sans, Muli, Lato",
		fontWeight: 800,
		fontSize: (isMobile) ? 'calc(20px + 0.5vw)': 'calc(22px + 0.4vw)',
		color:'#03234b', 
	},
	card_1: {
		width: '100%',
		minHeight:  (isMobile)?  'calc(50px + 4vw)' : 'calc(100px + 2vw)',
		display:'flex',
    flexDirection: 'column',
	},
	sub_card_1: {
		width:'100%',
		minHeight:  (isMobile)?  'calc(50px + 4vw)' : 'calc(100px + 2vw)',
		display:'flex',
    flexDirection: 'row',
	},
	sub_card_2: {
		width: '100%',
		minHeight:  (isMobile)?  'calc(50px + 4vw)' : 'calc(100px + 2vw)',
		background: '#fff',
		display:'flex',
		flexDirection: 'column',
    margin: (isMobile) ? '2% .4% 1% .4%' : '1% 0.2% 0% .2%',
    alignItems: 'center',
	},
	feature_card: {
		display: 'flex',
		justifyContent: 'center',
		alignSelf: 'center',
		width: '100%',
		height: (isMobile) ? '100px' : '150px',
		flexGrow: 1,
		flexShrink: 1,
		backgroundSize: (isMobile) ? '70% 100%' :  '60% 100%', 
 		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
	},
	feature_title: {
		width: '100%',
		fontFamily: "Open Sans, Muli, Lato",
		fontSize: (isMobile) ? 'calc(14px + 0.5vw)': 'calc(14px + 0.4vw)',
		fontWeight: 800,
		display: 'flex',
		alignItems: 'center',
		textAlign: 'center',
		justifyContent: 'center',
		color:'#f8f9fb',
		height: (isMobile) ? '60px' : '80px',
		background:'#07ded2',
	},
	feature_content: {
		margin: (isMobile) ? '5%'  : '5%', 
		fontSize: (isMobile) ? 'calc(12px + 0.5vw)': 'calc(10px + 0.4vw)',
		fontFamily: "Open Sans, Muli, Lato",
  	color: '#03234b',
  	textAlign: (isMobile) ? 'left' : 'justify',
		display: 'flex',
		alignItems: 'center',
		textAlign: 'left',
		justifyContent: 'center',
		minHeight: (isMobile) ? '60px' : '80px',
	},
	device_card: {
		display: 'flex', 
		flexDirection: (isMobile) ? 'column' : 'row', 
	},
	device_image: {
		width :  (isMobile) ? '100%' : '100%', 
		height: (isMobile) ? '600px' : '750px',  
		backgroundSize: '100% 100%', 
 		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
	},
	device_content: {
		fontSize: (isMobile) ? 'calc(12px + 0.5vw)': 'calc(10px + 0.4vw)',
		fontFamily: "Open Sans, Muli, Lato",
  	color: '#03234b',
  	textAlign: (isMobile) ? 'left' : 'justify',
		width :  (isMobile) ? '100%' : '50%',
		padding: isMobile ? '0%' : '0% 2% 2% 2%',
	},
	alga_api: {
		padding: (isMobile) ? '4% 0% 4% 0%' : '2% 0% 2% 0%',
		fontFamily: "Open Sans, Muli, Lato",
		fontSize: (isMobile) ? 'calc(15px + 0.5vw)': 'calc(15px + 0.4vw)',
		color:'#03234b',
	},
}));

const features = [
	[
		[
			{	
				title: 'CANTEEN MANAGEMENT APP', 
				img:'hrms/environmental.png',
				content: "From APP user authenticate their biometric and select"+
				 " food item available for meals and checkout to print the token."+
				 " User can check printer, paper and printer cover status from app."+
				 " It also send notification alert to registered mobile/email when"+
				 " printer cover opened/paper empty/any other problem."+
				 " Everyday APP automatic sync meals item for each meals times."+
				 " Token status is stored offline and synced with server when internet"+
				 " available.",
			},
			{	
				title: 'USER REPORT MANAGEMENT', 
				img:'hrms/environmental.png',
				content: "Each user can able to view their token status, token"+
				" history and food payment history in dashboard with custom filter."+
				" Canteen dashboard also have food apply portal before meals",
			},
		],
		[
			{
				title: 'ADMIN REPORT MANAGEMENT', 
				img:'hrms/environmental.png',
				content: " Our Canteen management app provide both default and food"+
				" apply portal options."+
				" Dashboard have option to take preparation list before meals,"+
				" if food apply portal is enabled. Dashboard have report for daily, "+
				" monthly and consolidated report of employee food status and history."+
				" Dashboard provide option to update food items, default food items,"+
				" meals time and  printer setting.",
			},
		]
	],	
];

const device_feature = [
	"Android OS powered touch display device",
	"Biometrics and RFID Smartcard authentication",
	"Centralized biometrics system, automatic finger template restore",
	"Offline data log and sync with server",
	"Machine level employee blocking for uninformed leave",
	"User specific language based voice response",
	"Remote connectivity include WiFi, GSM, GPRS, GPS, micro usb ethernet",
	"Battery powered",
	"Max paper roll diameter: 50mm",
	"Paper width: 57mm",
	"Print width: 48mm",
	"Print speed: 50-80mm/s",
	"Paper auto cutter",
	"Power: 12V, 2A",
];

function FeatureCard (props) 
{
	return (
		<div id={props.data.title} style={{width: '100%' }}>
			<div className={props.classes.feature_title}>
				{ props.data.title }
			</div>
			<div className={props.classes.feature_content}>
				{props.data.content}
			</div>
		</div>
	);
}

function Canteen() {
	const classes = useStyles();
	window.scrollTo(0, 0);
	
  return (
    <div id='orbidx-hrms' className={classes.root}>
    	<div className={classes.product_label}>
    		CANTEEN
    	</div>
    	<div className={classes.contact_title}>
				CANTEEN MANAGEMENT SYSTEM
			</div>
  		<div 
  			className={classes.wallpaper}
  			style={{ backgroundImage: `url(${require(`./assets/canteen/canteen.jpg`)})`}}
  		/>
			<div className={classes.features}>
				FEATURES
			</div>
			{
				features.map(element_array => 
					<div>
					<div className={classes.card_1}>
						<div className={classes.sub_card_1}>
							{
								element_array[0].map(element => 
									<Paper 
										elevation={1}
										square 
										className={classes.sub_card_2}
									>
										<FeatureCard classes={classes} data={element}/>
									</Paper>
								)
							}
							{
								(!isMobile) ? 
								element_array[1].map(element => 
									<Paper 
										elevation={1}
										square 
										className={classes.sub_card_2}
									>
										<FeatureCard classes={classes} data={element}/>
									</Paper>
								)	
								:
								''
							}
						</div>
					</div>
					{
						(isMobile) ?
							<div className={classes.sub_card_1}>
							{
								element_array[1].map(element => 
									<Paper 
										elevation={1}
										square 
										className={classes.sub_card_2}
									>
										<FeatureCard classes={classes} data={element}/>
									</Paper>
								)
							}	
							</div>
						:
						''
					}
					</div>
				)
			}
			<div className={classes.features}>
				CANTEEN PRINTER DEVICE
			</div>
			<div className= { classes.device_card }>
				<div 
					className={ classes.device_image }
					style = {{
						backgroundImage: `url(${require(`./assets/canteen/canteen-1.png`)})`
					}}
				/>
				<div className={classes.device_content}>
					<ul>
					{
						device_feature.map(element => <li>{element}</li>)
					}
					</ul>
				</div>
			</div>
    </div>
  );
}

export default Canteen;
