import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { isMobile } from "react-device-detect";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';

import AutoCarousel from "./AutoCarousel";

const useStyles = makeStyles((theme) => ({
  root: {
		padding: theme.spacing(2),
		display: 'flex', 
		flexDirection: 'column',
	},
	contact_title: {
		fontFamily: "Open Sans, Lato, Muli",
		color: '#03234b',
  	letterSpacing: '.015em',
  	fontWeight: 800,
   	fontSize: 'calc(22px + 0.6vw)',
	},
	content_1: {
		fontSize: (isMobile) ? 'calc(12px + 0.5vw)': 'calc(10px + 0.4vw)',
		fontFamily: "Open Sans, Muli, Lato",
  	color: '#03234b',
  	textAlign: (isMobile) ? 'left' : 'justify',
		padding: (isMobile) ? '4% 0 0 0'  : '2% 0 0 0',
	},
	list_root: {
		margin: (isMobile) ? '5% 0 0 0' : '5% 0% 0% 0%',
	},
	list_row: {
		width: '100%',
		display: 'flex', 
		flexDirection: 'row',
		flexGrow:1,
  	flexShrink:1,
  	justifyContent: 'space-between',
	},
	list_column: {
		width: '100%',
		display: 'flex', 
		flexDirection: 'column',
		flexGrow:1,
  	flexShrink:1,
  	justifyContent: 'space-between',
	},
	card: {
		width: (isMobile) ? '92%' : '80%',
		margin: (isMobile) ? '2% 0 2% 0' : '0.5%',
		minHeight:  (isMobile)?  'calc(100px + 4vw)' : 'calc(60px + 2vw)',
		background: '#fff',
		display:'flex',
    flexDirection: 'column',
    alignItems: 'center',
	},
	sub_card: {
		padding: (isMobile)? '5%' : '5% 3% 3% 3%',
	},
	icon: {
		height:  (isMobile)?  'calc(100px + 8vw)' : 'calc(120px + 4vw)',
		width: '100%', 
		backgroundSize: '85% 85%', 
 		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
	},
	list_content_1: {
		padding: (isMobile) ? '4% 0 0 0'  : '4% 0 0 0', 
		width: '100%',
		color:'#03234b', 
		fontFamily: "Open Sans, Muli, Lato",
		fontWeight: 800,
		letterSpacing: '0.1em',
		fontSize: (isMobile) ? 'calc(16px + 0.6vw)': 'calc(15px + 0.3vw)',
		textTransform: 'uppercase',
		display: 'flex',
		justifyContent: 'center',
		textAlign: 'center'
	},
	banner: {
		paddingTop: (isMobile) ? '4%' : '2%',
	},
	list_content_2: {
		padding: (isMobile) ? '4% 0 0 0'  : '2% 0 0 0', 
		fontSize: (isMobile) ? 'calc(12px + 0.5vw)': 'calc(10px + 0.4vw)',
		fontFamily: "Open Sans, Muli, Lato",
  	color: '#03234b',
		display: 'flex',
		justifyContent: 'center',
		textAlign: 'center',
		opacity: 0.95,
	},

}));

const items = [
	{img: 'web/web-development2.jpg'},
	{img: 'web/web-development1.jpg'},
	{img: 'web/webdevelopment3.jpg'},
];

const specialize= [
	[
		{
			title: 'WEB APPLICATION', 
			img: 'web/web-application.jpg', 
			content: "We design static, dynamic, portal web app and custom dashboard",
		},
		{	
			title: 'E-COMMERCE', 
			img: 'web/ecommerce.jpg', 
			content: "we develop eCommerce app for jewellery,"+ 
			" grocery, Electronics, travel booking or custom. We deliver full"+
			" functional eCommerce app from order to delivery",
		},
		{ 
			title: 'PROGRESSIVE WEB APPS', 
			img: 'web/pwa.jpg', 
			content: "We develop secure, fast loading, responsive, high performance,"+
				" native progressice web app",
		},
	],
	[
		{
			title: 'WEB ANALYTICS', 
			img: 'web/web-analytics.jpg', 
			content: "Our web analytics helps our customer to"+
				" understand the behavior of their website’s visitors, gain insight"+
				" into website’s performance and uncover problem areas to"+
				" maximize conversions.",
		},
		{
			title: 'SMM', 
			img: 'web/smm.jpg', 
			content: "We deliver social media promotion, brand management,"+
				" social media monitoring, social media advertising,"+
				" community management",
		},
		{
			title: 'SEO', 
			img: 'web/seo.jpg', 
			content: "We provide Technical SEO, On-Page SEO, Off Page SEO,"+
				" eCommerce SEO"
		},
	],
]; 

function WebDevelopment() {
	const classes = useStyles();
	window.scrollTo(0, 0);
	
  return (
    <div id='orbidx-web-development' className={classes.root}>
    	<div className={classes.contact_title}>
				WEB DEVELOPMENT
			</div>
			<div className={classes.banner}>
				<AutoCarousel items={items} indicators={false}/>
			</div>
			<div className={classes.content_1}>
				We offer custom and secure wep application to comprise all necessary 
				functionalities to accelerate and improve business. 
				Our team provide long-term support and on-demand support.  
			</div>
			<div className={classes.content_1}>
				We Specialize in
			</div>
			<div className={classes.list_root}>
			{
				specialize.map((row, index) =>
				<div 
					id={index} 
					className={(isMobile) ? classes.list_column : classes.list_row}
				>
				{
					row.map((element, index) =>  
						<Paper 
							elevation={1} 
							id={element.title} 
							className={classes.card}
						>
							<div 
								className={classes.icon} 
								style={{
									backgroundImage: `url(${require(`./assets/${element.img}`)})`
								}}
							>
							</div>
							<div className={classes.sub_card}>
								<div className={classes.list_content_1}>
									{element.title}
								</div>
								<div className={classes.list_content_2}>
									{element.content}
								</div>
							</div>
						</Paper>
					)
				}
				</div>
				)
			}
			</div>
    </div>
  );
}

export default WebDevelopment;
