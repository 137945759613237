import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import PhoneIcon from '@material-ui/icons/Phone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.spacing((isMobile) ? 2 :8), 
		height:'200px', 
		background:'#07ded2', 
		position:'fixed',
		marginTop: '4%',
		right: 0,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-evenly',
  },
  icon: {
  	color: '#fff',
  }
}));

function NavDrawer(props) {
	const classes = useStyles();

  return (
  	<div>
	  	<div className={classes.root}>
	  		<IconButton color="primary" onClick={props.forceUpdate} component={Link} to='/'>
	  			<HomeIcon className={classes.icon}/>
	  		</IconButton>
	  		<IconButton color="primary" onClick={props.forceUpdate} component={Link} to='/contact-us'>
	  			<MailOutlineIcon className={classes.icon}/>
	  		</IconButton>
		  	<IconButton color="primary" onClick={() => window.scrollTo(0, 0)}>
	  			<KeyboardArrowUpIcon fontSize="large"  className={classes.icon}/>
	  		</IconButton>
	  	</div>
		</div>
  );
}

export default NavDrawer;
