import React from 'react';
import Carousel from 'react-material-ui-carousel';
import autoBind from 'auto-bind';
import { Paper, Button, Typography } from '@material-ui/core'
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import { isMobile } from "react-device-detect";
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
	root: {
		width: '100%', 
		height: (isMobile) ? 'calc(125px + 0.5vw)' : 'calc(250px + 0.6vw)',
		overflow:'hidden',
	}, 
	banner: {
		minWidth: '100%',  
		minHeight: (isMobile) ? 'calc(125px + 0.5vw)' : 'calc(250px + 0.6vw)',
	 	display:'flex', 
	 	flexDirection: (isMobile) ? 'column' : 'row',
	 	backgroundSize: 'cover', 
	 	backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
	}
});

function Project(props)
{
	return (
		<Paper 
			square 
			className={props.classes.banner} 
			elevation={0}
			style={{backgroundImage: `url(${require(`./assets/${props.item.img}`)})`}}
		/>
	)
}

class AutoCarousel extends React.Component
{
	constructor(props)
	{
		super(props);
	}

	render()
	{
		const { classes } = this.props;

		return (
			<div className={classes.root}>
				<Carousel 
					autoPlay={true}
					timer={2000}
					animation={'slide'}
					timeout={500}
					indicators={this.props.indicators}
				>
					{
						this.props.items.map( (item, index) => {
							return (
								<Project 
									item={item} 
									index={index}
									onServiceTypeChange={this.props.onServiceTypeChange}
									key={index}
									classes={classes}
								/>
							)
						})
					}
				</Carousel>
			</div>
		)
	}
}

export default  withStyles(styles)(AutoCarousel);