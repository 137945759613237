import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { isMobile } from "react-device-detect";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  root: {
		padding: theme.spacing(2),
		display: 'flex', 
		flexDirection: 'column',
	},
	product_label: {
    width: theme.spacing((isMobile) ? 3 : 8), 
		minHeight: (isMobile) ? '120px' : '250px', 
		background:'#07ded2', 
		position:'fixed',
		top: (isMobile) ? '30%' : '35%',
		left: 0,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		fontFamily: "Open Sans, Lato, Muli",
		justifyContent: 'space-evenly',
		writingMode: 'vertical-lr',
		textOrientation: 'upright',
   	fontSize: (isMobile) ?  'calc(14px + 0.5vw)' : 'calc(18px + 0.5vw)',
   	fontWeight: 800,
   	color: '#f8f9fb',
  },
	wallpaper: {
		width : '100%', 
		height: (isMobile) ? '150px' : '400px',  
		backgroundSize: '100% 100%', 
 		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
	},
	contact_title: {
		fontFamily: "Open Sans, Lato, Muli",
		color: '#03234b',
  	letterSpacing: '.015em',
  	fontWeight: 800,
   	fontSize: 'calc(30px + 0.6vw)',
		marginBottom: '1%',
	},
	quote: {
		fontFamily: "Open Sans, Lato, Muli",
		color: '#03234b',
  	letterSpacing: '.015em',
  	fontWeight: 600,
   	fontSize: 'calc(32px + 0.6vw)',
   	alignSelf: 'center',
   	margin: (isMobile) ? '15% 0% 10% 0%' : '8% 0% 4% 0%',
	},
	content_1: {
		padding: (isMobile) ? '4% 0 0 0'  : '2% 0 0 0', 
		color:'#03234b', 
		fontFamily: "Open Sans, Muli, Lato",
		fontWeight: 600,
		fontSize: (isMobile) ? 'calc(11px + 0.5vw)': 'calc(10px + 0.4vw)',
	},
	features: {
		padding: (isMobile) ? '4% 0% 4% 0%' : '2% 0% 2% 0%',
		fontFamily: "Open Sans, Muli, Lato",
		fontWeight: 800,
		fontSize: (isMobile) ? 'calc(20px + 0.5vw)': 'calc(22px + 0.4vw)',
		color:'#03234b', 
	},
	card_1: {
		width: '100%',
		minHeight:  (isMobile)?  'calc(50px + 4vw)' : 'calc(100px + 2vw)',
		display:'flex',
    flexDirection: 'column',
	},
	sub_card_1: {
		width:'100%',
		minHeight:  (isMobile)?  'calc(50px + 4vw)' : 'calc(100px + 2vw)',
		display:'flex',
    flexDirection: 'row',
	},
	sub_card_2: {
		width: '100%',
		minHeight:  (isMobile)?  'calc(50px + 4vw)' : 'calc(100px + 2vw)',
		background: '#fff',
		display:'flex',
		flexDirection: 'column',
    margin: (isMobile) ? '2% .4% 1% .4%' : '1% 0.2% 0% .2%',
    alignItems: 'center',
	},
	feature_card: {
		display: 'flex',
		justifyContent: 'center',
		alignSelf: 'center',
		width: '100%',
		height: (isMobile) ? '100px' : '150px',
		flexGrow: 1,
		flexShrink: 1,
		backgroundSize: (isMobile) ? '70% 100%' :  '60% 100%', 
 		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
	},
	feature_title: {
		width: '100%',
		fontFamily: "Open Sans, Muli, Lato",
		fontSize: (isMobile) ? 'calc(14px + 0.5vw)': 'calc(14px + 0.4vw)',
		fontWeight: 800,
		display: 'flex',
		alignItems: 'center',
		textAlign: 'center',
		justifyContent: 'center',
		color:'#f8f9fb',
		height: (isMobile) ? '60px' : '80px',
		background:'#07ded2',
	},
	feature_content: {
		margin: (isMobile) ? '5%'  : '5%', 
		fontSize: (isMobile) ? 'calc(12px + 0.5vw)': 'calc(10px + 0.4vw)',
		fontFamily: "Open Sans, Muli, Lato",
  	color: '#03234b',
  	textAlign: (isMobile) ? 'left' : 'justify',
		display: 'flex',
		alignItems: 'center',
		textAlign: 'left',
		justifyContent: 'center',
		minHeight: (isMobile) ? '60px' : '80px',
	},
	device_card: {
		display: 'flex', 
		flexDirection: (isMobile) ? 'column' : 'row', 
	},
	device_image: {
		width :  (isMobile) ? '100%' : '50%', 
		height: (isMobile) ? '600px' : '750px',  
		backgroundSize: '100% 100%', 
 		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
	},
	device_content: {
		fontSize: (isMobile) ? 'calc(12px + 0.5vw)': 'calc(10px + 0.4vw)',
		fontFamily: "Open Sans, Muli, Lato",
  	color: '#03234b',
  	textAlign: (isMobile) ? 'left' : 'justify',
		width :  (isMobile) ? '100%' : '50%',
		padding: isMobile ? '0%' : '0% 2% 2% 2%',
	},
	alga_api: {
		padding: (isMobile) ? '4% 0% 4% 0%' : '2% 0% 2% 0%',
		fontFamily: "Open Sans, Muli, Lato",
		fontSize: (isMobile) ? 'calc(15px + 0.5vw)': 'calc(15px + 0.4vw)',
		color:'#03234b',
	},
}));

const features = [
	[
		[
			{	
				title: 'TRIP LOCATION MANAGEMENT', 
				img:'hrms/environmental.png',
				content: "Dashboard provide options to set location of"+
				 " factory in map using lat & long and smartcard to that location."+
				 " From dashboard we can create trip using the created location in map."+
				 " Dashboard provide user friendly location and trip"+
				 " management forms and reports."
			},
			{	
				title: 'TRIP SCHEDULE MANAGEMENT', 
				img:'hrms/environmental.png',
				content: "Dashboard proivde option to schedule trip for each"+
				" security in company by using trip data and employee ID."+
				" Trip can be schedule for single day or routine trip."+
				" Report provide user friendly functionality to manage employee trip"+
				" scheduling."
			},
		],
		[
			{
				title: 'TRIP REPORT MANAGEMENT', 
				img:'hrms/environmental.png',
				content: "Patrol device sync with server each and every location"+
				" scanned by security in real-time with gps location."+
				" From dashboard user can view the complete trip gps location"+
				" and scan time of security in map."
			},
			{
	 			title: 'ALERT MANAGEMENT', 
	 			img:'hrms/environmental.png',
	 			content: "Device monitor security activities and update to server in"+
	 			" real time. It alert security for each trip start time and"+
	 			" provide voice based navigation for next location to scan."+
	 			" If trip missed then sms or call or missed call alert will be send to"+
	 			" registered mobile numbers. Application show all location of trips in"+
	 			" live map which help security to locate position in factory."
	 		},
		]
	],	
	
];

const device_feature = [
	"Android OS powered touch display device",
	"Biometrics and RFID Smartcard authentication",
	"Centralized biometrics system, automatic finger template restore",
	"Offline data log and sync with server",
	"Machine level employee blocking for uninformed leave",
	"User specific language based voice response",
	"Remote connectivity include WiFi, GSM, GPRS, GPS, micro usb ethernet",
	"Thermal printer interface or serial communication external device"+
	" interface port available",
	"Battery powered",
];

function FeatureCard (props) 
{
	/*	
		<div className={props.classes.feature_card} 
			style={{backgroundImage: `url(${require(`./assets/${props.data.img}`)})`}}
		/>
	*/
	return (
		<div id={props.data.title} style={{width: '100%' }}>
			<div className={props.classes.feature_title}>
				{ props.data.title }
			</div>
			<div className={props.classes.feature_content}>
				{props.data.content}
			</div>
		</div>
	);
}

function SecurityPatrol() {
	const classes = useStyles();
	window.scrollTo(0, 0);
	
  return (
    <div id='orbidx-hrms' className={classes.root}>
    	<div className={classes.product_label}>
    		PATROL
    	</div>
    	<div className={classes.contact_title}>
				SECURITY PATROL SYSTEM
			</div>
  		<div 
  			className={classes.wallpaper}
  			style={{ backgroundImage: 
  				`url(${require(`./assets/security/security1.jpg`)})`}}
  		/>
  		<div className={classes.quote}>
				Product That Guard Your Factory
			</div>
			<div className={classes.features}>
				FEATURES
			</div>
			{
				features.map(element_array => 
					<div>
					<div className={classes.card_1}>
						<div className={classes.sub_card_1}>
							{
								element_array[0].map(element => 
									<Paper 
										elevation={1}
										square 
										className={classes.sub_card_2}
									>
										<FeatureCard classes={classes} data={element}/>
									</Paper>
								)
							}
							{
								(!isMobile) ? 
								element_array[1].map(element => 
									<Paper 
										elevation={1}
										square 
										className={classes.sub_card_2}
									>
										<FeatureCard classes={classes} data={element}/>
									</Paper>
								)	
								:
								''
							}
						</div>
					</div>
					{
						(isMobile) ?
							<div className={classes.sub_card_1}>
							{
								element_array[1].map(element => 
									<Paper 
										elevation={1}
										square 
										className={classes.sub_card_2}
									>
										<FeatureCard classes={classes} data={element}/>
									</Paper>
								)
							}	
							</div>
						:
						''
					}
					</div>
				)
			}
			<div className={classes.features}>
				SECURITY PATROL DEVICE
			</div>
			<div className= { classes.device_card }>
				<div 
					className={ classes.device_image }
					style = {{
						backgroundImage: `url(${require(`./assets/security/patrol.png`)})`
					}}
				/>
				<div 
					className={ classes.device_image }
					style = {{
						backgroundImage: `url(${require(`./assets/security/patrol_1.png`)})`
					}}
				/>
				<div className={classes.device_content}>
					<ul>
					{
						device_feature.map(element => <li>{element}</li>)
					}
					</ul>
				</div>
			</div>
    </div>
  );
}

export default SecurityPatrol;
