import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { isMobile } from "react-device-detect";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  root: {
		padding: theme.spacing(2),
		display: 'flex', 
		flexDirection: 'column',
	},
	product_label: {
    width: theme.spacing((isMobile) ? 3 : 8), 
		minHeight: (isMobile) ? '120px' : '150px', 
		background:'#07ded2', 
		position:'fixed',
		top: (isMobile) ? '30%' : '35%',
		left: 0,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		fontFamily: "Open Sans, Lato, Muli",
		justifyContent: 'space-evenly',
		writingMode: 'vertical-lr',
		textOrientation: 'upright',
   	fontSize: (isMobile) ?  'calc(14px + 0.5vw)' : 'calc(12px + 0.5vw)',
   	fontWeight: 800,
   	color: '#f8f9fb',
  },
	wallpaper: {
		width : '100%', 
		minHeight: (isMobile) ? '150px' : '400px',  
		backgroundSize: '100% 100%', 
 		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
	},
	contact_title: {
		fontFamily: "Open Sans, Lato, Muli",
		color: '#03234b',
  	letterSpacing: '.015em',
  	fontWeight: 800,
   	fontSize: 'calc(30px + 0.6vw)',
		marginBottom: '1%',
	},
	quote: {
		fontFamily: "Open Sans, Lato, Muli",
		color: '#03234b',
  	letterSpacing: '.015em',
  	fontWeight: 600,
   	fontSize: 'calc(32px + 0.6vw)',
   	alignSelf: 'center',
   	margin: (isMobile) ? '15% 0% 10% 0%' : '8% 0% 4% 0%',
	},
	content_1: {
		fontSize: (isMobile) ? 'calc(12px + 0.5vw)': 'calc(10px + 0.4vw)',
		fontFamily: "Open Sans, Muli, Lato",
  	color: '#03234b',
  	textAlign: (isMobile) ? 'left' : 'justify',
		padding: (isMobile) ? '4% 0 0 0'  : '2% 0 0 0',
	},
	features: {
		padding: (isMobile) ? '4% 0% 4% 0%' : '2% 0% 2% 0%',
		fontFamily: "Open Sans, Muli, Lato",
		fontWeight: 800,
		fontSize: (isMobile) ? 'calc(20px + 0.5vw)': 'calc(22px + 0.4vw)',
		color:'#03234b', 
	},
	card_1: {
		width: '100%',
		minHeight:  (isMobile)?  'calc(50px + 4vw)' : 'calc(100px + 2vw)',
		display:'flex',
    flexDirection: 'column',
	},
	sub_card_1: {
		width:'100%',
		minHeight:  (isMobile)?  'calc(50px + 4vw)' : 'calc(100px + 2vw)',
		display:'flex',
    flexDirection: 'row',
	},
	sub_card_2: {
		width: '100%',
		minHeight:  (isMobile)?  'calc(50px + 4vw)' : 'calc(100px + 2vw)',
		background: '#fff',
		display:'flex',
		flexDirection: 'column',
    margin: (isMobile) ? '2% .4% 1% .4%' : '1% 0.2% 0% .2%',
    alignItems: 'center',
	},
	feature_card: {
		display: 'flex',
		justifyContent: 'center',
		alignSelf: 'center',
		width: '100%',
		height: (isMobile) ? '100px' : '150px',
		flexGrow: 1,
		flexShrink: 1,
		backgroundSize: (isMobile) ? '70% 100%' :  '60% 100%', 
 		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
	},
	feature_title: {
		width: '100%',
		fontFamily: "Open Sans, Muli, Lato",
		fontSize: (isMobile) ? 'calc(14px + 0.5vw)': 'calc(14px + 0.4vw)',
		fontWeight: 800,
		display: 'flex',
		alignItems: 'center',
		textAlign: 'center',
		justifyContent: 'center',
		color:'#f8f9fb',
		height: (isMobile) ? '60px' : '80px',
		background:'#07ded2',
	},
	feature_content: {
		margin: (isMobile) ? '5%'  : '5%', 
		fontSize: (isMobile) ? 'calc(12px + 0.5vw)': 'calc(10px + 0.4vw)',
		fontFamily: "Open Sans, Muli, Lato",
  	color: '#03234b',
  	textAlign: (isMobile) ? 'left' : 'justify',
		display: 'flex',
		alignItems: 'center',
		textAlign: 'left',
		justifyContent: 'center',
		minHeight: (isMobile) ? '60px' : '80px',
	},
	device_card: {
		display: 'flex', 
		flexDirection: (isMobile) ? 'column' : 'row', 
	},
	device_image: {
		width :  (isMobile) ? '100%' : '50%', 
		height: (isMobile) ? '600px' : '750px',  
		backgroundSize: '100% 100%', 
 		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
	},
	device_content: {
		fontSize: (isMobile) ? 'calc(12px + 0.5vw)': 'calc(10px + 0.4vw)',
		fontFamily: "Open Sans, Muli, Lato",
  	color: '#03234b',
  	textAlign: (isMobile) ? 'left' : 'justify',
		width :  (isMobile) ? '100%' : '50%',
		padding: isMobile ? '0%' : '0% 2% 2% 2%',
	},
	alga_api: {
		padding: (isMobile) ? '4% 0% 4% 0%' : '2% 0% 2% 0%',
		fontFamily: "Open Sans, Muli, Lato",
		fontSize: (isMobile) ? 'calc(15px + 0.5vw)': 'calc(15px + 0.4vw)',
		color:'#03234b',
	},
}));

const features = [
	[
		[
			{	
				title: 'DASHBOARD', 
				img:'hrms/environmental.png',
				content: "Two different views available in dashboard; one for employee"+
				 " and another for Superuser/Admin. Employees can view their current &"+
				 " next shift, working days, leave days, onduty days, weekends,"+
				 " company leave days, present days, balance minute and permission"+
				 " minutes. In addition superuser/admin can view total number of "+
				 " employees in onwork, onleave, approved leave & permission and "+
				 " available SMS credits. It also provides detailed report in tabular "+
				 " view by selecting any services.",
			},
			{	
				title: 'CALENDAR', 
				img:'hrms/environmental.png',
				content: "Calendar provides two views: Employee calendar and Company calendar. In employee calendar employee can view their weekends, company leaves, in and out punch time with shift info, late minutes, balance late minutes, absent, leave, permission and onduty. In company calendar superuser/admin can view total number employees on work and on leave with detailed report in a tabular view by selecting a specific day.",
			},
		],
		[
			{
				title: 'LEAVE MANAGEMENT', 
				img:'hrms/environmental.png',
				content: "Employees can apply their leave, permission, onduty from dashboard by selecting available days and time from calendar which is configured in company settings. It enables employees to view their status and history in a tabular view and also provides option to cancel leave / permission / onduty before any approval.",
			},
			{
	 			title: 'EMPLOYEE MANAGEMENT', 
	 			img:'hrms/environmental.png',
	 			content: "Employee management is accessed only by superuser / admin which enables them to create / update/ relieve / view employee, view employee calendar and attendance history, add / cancel / view manual leave / permission / onduty for employee, unblock attendance and shift blocked employee, create / update / remove / view approval hierarchy for employee, reset employee account password and much more.",
	 		},
		]
	],	
	[ 
	 	[
	 		{
	 			title: 'BLOCK MANAGEMENT', 
	 			img:'hrms/environmental.png',
	 			content: "In case of uninformed leave, the device will block the employee attendance which will be later approved or dismissed by managers. An employee can also view their block report in the dashboard. Alga also provides manual block option for managers.",
	 		},
	 		{
				title: 'MISS PUNCH MANAGEMENT', 
				img:'hrms/environmental.png',
				content: "In case an employee misses an in/out punch in the device then the employee can request punch from dashboard, upon approval the punch will be added. An employee can also view their attendance history in a tabular view.",
			},
	 	],
		[
			{
				title: 'TEAM MANAGEMENT', 
				img:'hrms/environmental.png',
				content: "Team management enables superuser / admin to create / update/ remove / view team / sub team from dashboard which will be added upon new employee create.",
			},
			{
				title: 'SHIFT MANAGEMENT', 
				img:'hrms/environmental.png',
				content: "Shift management enables superuser / admin to create / update/ remove / view company shifts; also enables to add regular/OT shift to employees.",
			},
		]
	],
	[
		[
			{
				title: 'SMS AND EMAIL', 
				img:'hrms/environmental.png',
				content: "In SMS and Email management superuser / admin can configure the SMS and email server; also enables to send individual or group email or SMS to employees.",
			},
			{
				title: 'CUSTOM CONFIGURATION', 
				img:'hrms/environmental.png',
				content: "Company setting is accessed only by admin in which admin can add company leave, unit, designation, role, device settings, basic company information, calendar date settings.",
			},
		],
		[
			{
				title: 'NOTIFICATION', 
				img:'hrms/environmental.png',
				content: "Alga offers real-time notification to employees, managers, team leaders, hierarchy employees via dashboard, SMS and email in the event of leave / permission / onduty / miss punch request or attendance / shift / manual blocked employees. Custom alert or notification to different hierarchy employees will be provided based on customer requirement.",
			},
			{
				title: 'CUSTOM REPORT', 
				img:'hrms/environmental.png',
				content: "Our team will provide custom forms and reports based on customer requirement. No restriction for customization!",
			},
		]
	]
];

const device_feature = [
	"Android OS powered touch display device",
	"Biometrics and RFID Smartcard authentication",
	"Centralized biometrics system, automatic finger template restore",
	"Offline data log and sync with server",
	"Machine level employee blocking for uninformed leave",
	"User specific language based voice response",
	"Remote connectivity include WiFi, GSM, GPRS, GPS, micro usb ethernet",
	"Thermal printer interface or serial communication external device"+
	" interface port available",
	"Battery powered",
];

function FeatureCard (props) 
{
	/*
		<div className={props.classes.feature_card} 
			style={{backgroundImage: `url(${require(`./assets/${props.data.img}`)})`}}
		/>
	*/
	return (
		<div id={props.data.title} style={{width: '100%' }}>
			<div className={props.classes.feature_title}>
				{ props.data.title }
			</div>
			<div className={props.classes.feature_content}>
				{props.data.content}
			</div>
		</div>
	);
}

function HRMS() {
	const classes = useStyles();
	window.scrollTo(0, 0);
	
  return (
    <div id='orbidx-hrms' className={classes.root}>
    	<div className={classes.product_label}>
    		ALGA
    	</div>
    	<div className={classes.contact_title}>
				HUMAN RESOURCE MANAGEMENT SYSTEM
			</div>
  		<div 
  			className={classes.wallpaper}
  			style={{ backgroundImage: 
  				`url(${require(`./assets/web/webdevelopment2.png`)})`
  			}}
  		/>
  		<div className={classes.quote}>
				Product That Transform Your Business
			</div>
			<div className={classes.features}>
				FEATURES
			</div>
			{
				features.map(element_array => 
					<div>
					<div className={classes.card_1}>
						<div className={classes.sub_card_1}>
							{
								element_array[0].map(element => 
									<Paper 
										elevation={1}
										square 
										className={classes.sub_card_2}
									>
										<FeatureCard classes={classes} data={element}/>
									</Paper>
								)
							}
							{
								(!isMobile) ? 
								element_array[1].map(element => 
									<Paper 
										elevation={1}
										square 
										className={classes.sub_card_2}
									>
										<FeatureCard classes={classes} data={element}/>
									</Paper>
								)	
								:
								''
							}
						</div>
					</div>
					{
						(isMobile) ?
							<div className={classes.sub_card_1}>
							{
								element_array[1].map(element => 
									<Paper 
										elevation={1}
										square 
										className={classes.sub_card_2}
									>
										<FeatureCard classes={classes} data={element}/>
									</Paper>
								)
							}	
							</div>
						:
						''
					}
					</div>
				)
			}
			<div className={classes.features}>
				ALGA BIOMETRIC DEVICE
			</div>
			<div className= { classes.device_card }>
				<div 
					className={ classes.device_image }
					style = {{
						backgroundImage: `url(${require(`./assets/hrms/payroll.png`)})`
					}}
				/>
				<div 
					className={ classes.device_image }
					style = {{
						backgroundImage: `url(${require(`./assets/hrms/payroll_1.png`)})`
					}}
				/>
				<div className={classes.device_content}>
					<ul>
					{
						device_feature.map(element => <li>{element}</li>)
					}
					</ul>
				</div>
			</div>
			<div className={classes.alga_api}>
				We also provide API to interface with other vendor biometric device 
			</div>
    </div>
  );
}

export default HRMS;
