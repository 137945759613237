import { createMuiTheme } from '@material-ui/core/styles';

/* Color selected from https://material.io/resources/color/#!/ */
export const appTheme  = (type) => createMuiTheme({
  palette: {
    primary: {
      light: '#374a77',
      main: '#03234b',
      dark: '#000024',
      contrastText: '#fff',
    },   
    secondary: {
      light: '#68ffff',
      main: '#07ded2',
      dark: '#00aba1',
      contrastText: '#fff',
    },
    success: {
      main: '#4CAF50',
    },
    warning: {
      main: '#ff9800'
    }, 
    error : {
      main: '#f44336'
    },
    info: {
      main: '#2196F3'
    }
  },
});
