import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { isMobile } from "react-device-detect";
import { MuiThemeProvider } from '@material-ui/core/styles';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from "react-router-dom";

import NavDrawer from './NavDrawer';
import Footer from './Footer';
import HomePage from './HomePage';
import AppBar from './AppBar';
import ContactUs from './ContactUs';
import CircuitDesign from './CircuitDesign';
import PCBDesign from './PCBDesign';
import Embedded from './Embedded';
import WebDevelopment from './WebDevelopment';
import AndroidApplication from './AndroidApplication';
import IOT from './IOT';
import HRMS from './HRMS';
import SecurityPatrol from './SecurityPatrol';
import Canteen from './Canteen';
import EmployeeInOut from './EmployeeInOut';
import VehicleInOut from './VehicleInOut';
import { appTheme } from './Theme';

const theme = appTheme('main');
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginTop: theme.spacing(4),
      marginLeft: theme.spacing((isMobile) ? 2 :8),
      marginRight: theme.spacing((isMobile) ?  2 :8),
      marginBottom: theme.spacing(2),
    },
    background:'#f8f9fb',
    width:'100%',
    height: '100%',
  },
}));

function App() {
	const classes = useStyles();
	const [value, setValue] = useState(0); // integer state

	const forceUpdate = () => {
		setValue(value => ++value);
	}

  return (
  	<MuiThemeProvider theme={theme}>
		  <Router>
		  	{
		  		(!isMobile && (window.location.pathname != '/')) ? 
		  		<NavDrawer forceUpdate={forceUpdate}/>
		  		:
		  		''
		  	}
    		<div id='orbidx-root' className={classes.root}>
		    	<AppBar/>
		    	<Switch>
		    		<Route exact path='/' component={() => 
		    			<HomePage forceUpdate={forceUpdate}/>} 
		    		/>
	          <Route path='/contact-us' component={ContactUs}/>
	          <Route path='/electronic-circuit-design' component={CircuitDesign}/>
	          <Route path='/pcb-design' component={PCBDesign}/>
	          <Route path='/embedded-software-development' 
	          	component={Embedded}
	          />
	          <Route path='/web-development' component={WebDevelopment}/>
	          <Route path='/iot' component={IOT}/>
	          <Route path='/android-application' component={AndroidApplication}/>
	          <Route path='/human-resource-management-system' component={HRMS}/>
	          <Route path='/security-patrol' component={SecurityPatrol}/>
	          <Route path='/canteen-management-system' component={Canteen}/>
	          <Route path='/employee-in-out-tracking' component={EmployeeInOut}/>
	          <Route path='/vehicle-in-out-tracking' component={VehicleInOut}/>
		      </Switch>
		      <Footer forceUpdate={forceUpdate}/>
    		</div>
	    </Router>
	  </MuiThemeProvider>
  );
}

export default App;
