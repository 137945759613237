import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { isMobile } from "react-device-detect";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';

import AutoCarousel from "./AutoCarousel";

const useStyles = makeStyles((theme) => ({
  root: {
		padding: theme.spacing(2),
		display: 'flex', 
		flexDirection: 'column',
	},
	contact_title: {
		fontFamily: "Open Sans, Lato, Muli",
		color: '#03234b',
  	letterSpacing: '.015em',
  	fontWeight: 800,
   	fontSize: 'calc(22px + 0.6vw)',
	},
	content_1: {
		fontSize: (isMobile) ? 'calc(12px + 0.5vw)': 'calc(10px + 0.4vw)',
		fontFamily: "Open Sans, Muli, Lato",
  	color: '#03234b',
  	textAlign: (isMobile) ? 'left' : 'justify',
		padding: (isMobile) ? '4% 0 0 0'  : '2% 0 0 0',
	},
	list_root: {
		margin: (isMobile) ? '5% 0 0 0' : '5% 0% 0% 0%',
	},
	list_row: {
		width: '100%',
		display: 'flex', 
		flexDirection: 'row',
		flexGrow:1,
  	flexShrink:1,
  	justifyContent: 'space-between',
	},
	list_column: {
		width: '100%',
		display: 'flex', 
		flexDirection: 'column',
		flexGrow:1,
  	flexShrink:1,
  	justifyContent: 'space-between',
	},
	card: {
		width: (isMobile) ? '92%' : '80%',
		padding: (isMobile)? '4%' : '2%',
		margin: (isMobile) ? '2% 0 2% 0' : '0.3%',
		minHeight:  (isMobile)?  'calc(100px + 0.4vw)' : 'calc(125px + 0.4vw)',
		background: '#07ded2',
	},
	list_content_1: {
		width: '100%',
		color:'#fff', 
		fontFamily: "Open Sans, Muli, Lato",
		fontWeight: 800,
		letterSpacing: '0.1em',
		fontSize: (isMobile) ? 'calc(16px + 0.6vw)': 'calc(15px + 0.3vw)',
	},
	list_content_2: {
		paddingTop: '5%', 
		width: '100%',
		color:'#fff', 
		fontFamily: "Open Sans, Muli, Lato",
		fontWeight: 600,
		fontSize: (isMobile) ? 'calc(12px + 0.5vw)': 'calc(10px + 0.4vw)',
	},
	banner: {
		paddingTop: (isMobile) ? '4%' : '2%',
	},
}));

const items = [
	{img: 'circuitdesign/prototype1.jpg'},
	{img: 'circuitdesign/circuit2.png'},
	{img: 'circuitdesign/circuit1.jpg'},
];

const list = [
	[
		{
			title: 'ANALOG CIRCUIT', 
			content: [ 
				`Analog Front End, Sensor Interface,`+
				` AC and DC Power Monitoring, Noise Filter`, 
			]
		},
		{	
			title: 'DIGITAL CIRCUIT', 
			content: [
				`Led Driver, Multiplexer, High Power Switching, Motor Driver,`+
			 	` Sensor Interface, Noise Filter`
			]
		},
		{
			title: 'POWER SUPPLY', 
			content: [
				`Flyback Power Supply, AC-AC Converter, `+ 
				`DC-DC Converter, Buck-Boost Converter, `+
				`Battery Charging and Discharging`
			]
		},
	],
	[
		{	
			title: 'WIRED COMMUNICATION', 
			content: [
				`Ethernet, I2C, 4-20mA, SPI, USB, UART, RS485, SDIO`
			]
		},
		{	
			title: 'WIRELESS COMMUNICATION', 
			content: [
				`WiFi, Bluetooth(Mesh), Thread, ZigBee, RF, LoRa, UWB, GSM/GPRS/GPS`
			]
		},
		{ title: 'MICROCONTROLLER INTERFACE', content: [`ARM, PIC, AVR`]},
	],
];

function CircuitDesign() {
	const classes = useStyles();
	window.scrollTo(0, 0);

  return (
    <div id='orbidx-circuit-design' className={classes.root}>
    	<div className={classes.contact_title}>
				ELECTRONIC CIRCUIT DESIGN AND PROTOTYPING
			</div>
			<div className={classes.banner}>
				<AutoCarousel items={items} indicators={false}/>
			</div>
			<div className={classes.content_1}>
				We provide solution for your issuse in circuit design and 
				circuit design guide for your inovation with commercial or 
				industrial grade components. Our team will solve your issuse with 
				industrial recommended components and new technology. 
			</div>
			<div className={classes.content_1}>
				Solutions we deliver
			</div>
			<div className={classes.list_root}>
			{
				list.map((row, index) =>
				<div 
					id={index} 
					className={(isMobile) ? classes.list_column : classes.list_row}
				>
				{
					row.map(element =>  
						<Paper 
							elevation={1} 
							id={element.title} 
							className={classes.card}
						>
							<div className={classes.list_content_1}>
								{element.title}
							</div>
							<div className={classes.list_content_2}>
								{element.content}
							</div>
						</Paper>
					)
				}
				</div>
				)
			}
			</div>
    </div>
  );
}

export default CircuitDesign;
