import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import {isMobile} from "react-device-detect";

import logo from './assets/logo.png';

const useStyles = makeStyles((theme) => ({
  appbar: {
  	display: 'flex',
    flexDirection: 'row',
  },
  company_name:{
  	padding: (isMobile) ? '2% 0 0 3%' : '0.1% 0 0 3%',
  	fontFamily: "Open Sans, Lato, Roboto",
  	color: '#0a2437',
  	fontSize: (isMobile) ? 'calc(30px + 2vw)' : 'calc(28px + 2vw)',
  	fontWeight: 800,
    letterSpacing: '.15em',
   	alignSelf: 'center',
   	textDecoration: 'none',
  },
  large: {
    width: (isMobile) ? 'calc(50px + 3vw)' : 'calc(40px + 3vw)',
    height: (isMobile) ? 'calc(50px + 3vw)' : 'calc(40px + 3vw)',
    alignSelf: 'center',
  },
}));

function AppBar() {
	const classes = useStyles();

  return (
  	<div  id='orbidx-appbar' className={classes.appbar}>
  		<a href='/'>
  			<Avatar variant="square" src={logo} className={classes.large}/>
  		</a>
    	<a href='/' className={classes.company_name}> ORBIDX </a>
    </div>
  );
}

export default AppBar;
