import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { isMobile } from "react-device-detect";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Link, withRouter } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  product_service :{
  	display: 'flex',
    flexDirection: (isMobile) ? 'column' : 'row',
    padding: '0% 0 0 0%',
  },
  product_paper : {
  	width: (isMobile) ? '90%' : '90%',
  	minHeight: '300px', 
  	margin: '2% 1% 2% 1%',
  	alignText: 'left',
  	padding: '3%'
  }, 
  product_text : {
  	fontFamily: "Open Sans, Lato, Muli",
  	color: '#0a2437',
  	letterSpacing: '.025em',
  	fontWeight: 800,
   	fontSize: 'calc(20px + 0.5vw)',
  },
  product_brief: {
  	minHeight: '140px', 
  	fontFamily: "Muli, Lato, Open Sans",
  	color: '#03234b',
  	paddingTop: '2%',
  	paddingBottom: '2%',
  	opacity: 0.6,
  },
  product_list: {
  	width:'100%', 
  	height:'55px',
    background:'#07ded2',
    color: '#fff', 
    marginTop:'4%',
    '&:hover': {
      background: '#f8f9fb',
      color: '#03234b',
    }, 
    display:'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textDecoration: 'None',
  },
  product_item:{
  	width:'90%',
  	paddingLeft: '3%',
    fontFamily: "Open Sans, Lato, Muli",
    letterSpacing: '.1em',
    fontWeight: 600,
   	fontSize: (isMobile) ? 'calc(10px + 0.5vw)' : 'calc(5px + 0.6vw)',
  },
  service_list: {
  	width:'100%', 
  	height:'55px',
    background:'#0a2437',
    color: '#fff', 
    marginTop:'4%',
    '&:hover': {
      background: '#f8f9fb',
      color: '#03234b',
    }, 
    display:'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textDecoration: 'None',
  },
  arrow: {
  	width:'15%',
  },
}));

let products = [
	{
		name: 'HUMAN RESOURCE MANAGEMENT SYSTEM', 
		link: '/human-resource-management-system'
	}, 
	{name: 'SECURITY PATROL SYSTEM', link: '/security-patrol'}, 
	{name: 'CANTEEN MANAGEMENT SYSTEM', link: '/canteen-management-system'}, 
	{name: 'EMPLOYEE IN/OUT TRACKING SYSTEM', link: '/employee-in-out-tracking'}, 
	{name: 'VEHICLE IN/OUT TRACKING SYSTEM', link: '/vehicle-in-out-tracking'},
	{
		name: 'INDUSTRIAL DATA ACQUISITION 4.0', 
		link: '#'
	},
	{	
		name: 'AGRICULTURE DATA ACQUISITION SYSTEM', 
		link: '#'
	},
];

let services = [
	{
		name: 'ELECTRONIC CIRCUIT DESIGN AND PROTOTYPING', 
		link: 'electronic-circuit-design'
	},
	{name: 'PCB DESIGN', link: 'pcb-design'},
	{name: 'EMBEDDED SOFTWARE DEVELOPMENT', link: 'embedded-software-development'}, 
	{name: 'WEB DEVELOPMENT', link: 'web-development'},
	{name: 'IoT', link: 'iot'},
	{name: 'ANDROID APPLICATION', link: 'android-application'},
	{name: 'iOS APPLICATION', link: '#'}, 
];

function HomePage(props) {
	const classes = useStyles();
	window.scrollTo(0, 0);

  return (
    <div id='orbidx-home' className={classes.product_service}>
    	<Paper elevation={0} square className={classes.product_paper}>
    		<Typography variant='h5' className={classes.product_text}>
	         SERVICES
    		</Typography>
    		<Typography variant='subtitle1' className={classes.product_brief}>
	        We offer a continuum of services to help accelerate your business. 
	        Our expertise lies in providing dedicated services to your business 
	        needs. From custom Electronic devices to complex Web apps, 
	        we provide solutions that target your needs. Our state-of-the-art 
	        solutions puts your business ahead in the race.
    		</Typography>
    		{
    			services.map((element, idx) =>  
	      		<Paper 
	      			elevation={0} 
	      			square 
	      			className={classes.service_list} 
	      			id={element.name}
	      			component={Link}
	      			to={element.link} 
	      			onClick ={props.forceUpdate}
	      		>
	      			<div className={classes.product_item}>
	      				{element.name}
	      			</div>
	      			{
	      				(element.link != '#') ? 
	      				<ArrowForwardIcon className={classes.arrow}/>
	      				:
	      				''
	      			}
	      		</Paper>
    			)
    		}
    	</Paper>
    	<Paper elevation={0} square className={classes.product_paper}>
    		<div className={classes.product_text}>
	        PRODUCTS
    		</div>
    		<Typography variant='subtitle1' className={classes.product_brief}>
    			Innovation is at the core of all our products. Our team understands 
    			that all businesses are unique and require unique solutions to tackle
    			the challenges. We offer products to solve your business needs. 
    			What's more? Our products ship with endless support and maintenance
    		</Typography>
    		{
    			products.map((element, idx) =>  
	      		<Paper 
	      			elevation={0} 
	      			square 
	      			className={classes.product_list} 
	      			id={element.name}
	      			component={Link}
	      			to={element.link} 
	      			onClick ={props.forceUpdate}
	      		>
	      			<div className={classes.product_item}>
	      				{element.name}
	      			</div>
	      			{
	      				(element.link != '#') ? 
	      				<ArrowForwardIcon className={classes.arrow}/>
	      				:
	      				''
	      			}
	      		</Paper>
    			)
    		}
    	</Paper>
    </div>
  );
}

export default HomePage;
