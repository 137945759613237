import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { isMobile } from "react-device-detect";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';

import AutoCarousel from "./AutoCarousel";

const useStyles = makeStyles((theme) => ({
  root: {
		padding: theme.spacing(2),
		display: 'flex', 
		flexDirection: 'column',
	},
	contact_title: {
		fontFamily: "Open Sans, Lato, Muli",
		color: '#03234b',
  	letterSpacing: '.015em',
  	fontWeight: 800,
   	fontSize: 'calc(22px + 0.6vw)',
	},
	content_1: {
		fontSize: (isMobile) ? 'calc(12px + 0.5vw)': 'calc(10px + 0.4vw)',
		fontFamily: "Open Sans, Muli, Lato",
  	color: '#03234b',
  	textAlign: (isMobile) ? 'left' : 'justify',
		padding: (isMobile) ? '4% 0 0 0'  : '2% 0 0 0',
	},
	list_root: {
		margin: (isMobile) ? '5% 0 0 0' : '5% 0% 0% 0%',
	},
	list_row: {
		width: '100%',
		display: 'flex', 
		flexDirection: 'row',
		flexGrow:1,
  	flexShrink:1,
  	justifyContent: 'space-between',
	},
	list_column: {
		width: '100%',
		display: 'flex', 
		flexDirection: 'column',
		flexGrow:1,
  	flexShrink:1,
  	justifyContent: 'space-between',
	},
	card: {
		width: (isMobile) ? '92%' : '80%',
		padding: (isMobile)? '4%' : '2%',
		margin: (isMobile) ? '2% 0 2% 0' : '0.3%',
		minHeight:  (isMobile)?  'calc(50px + 0.4vw)' : 'calc(60px + 0.4vw)',
		background: '#07ded2',
		display:'flex',
    flexDirection: 'row',
    alignItems: 'center',
	},
	list_content_1: {
		width: '100%',
		color:'#fff', 
		fontFamily: "Open Sans, Muli, Lato",
		fontWeight: 800,
		letterSpacing: '0.1em',
		fontSize: (isMobile) ? 'calc(16px + 0.6vw)': 'calc(15px + 0.3vw)',
		textTransform: 'uppercase',
		display: 'flex',
		justifyContent: 'center',
		textAlign: 'center'
	},
	banner: {
		paddingTop: (isMobile) ? '4%' : '2%',
	},
}));

const items = [
	{img: 'pcb_design/pcb4.jpg'},
	{img: 'pcb_design/pcb2.jpg'},
	{img: 'pcb_design/pcb1.jpg'},
];

const pcb_solutions= [
	[
		"Single Layer, Double Layer, Multi Layer PCB",
		"Analog Circuit",
		"Digital circuit",
	],
	[ 
		"High Frequency Signals",
		"High Power",
		"Wireless (WiFi, Bluetooth, RF) PCB Antenna",
	]
]; 

function PCBDesign() {
	const classes = useStyles();
	window.scrollTo(0, 0);
	
  return (
    <div id='orbidx-pcb-design' className={classes.root}>
    	<div className={classes.contact_title}>
				PCB DESIGN	
			</div>
			<div className={classes.banner}>
				<AutoCarousel items={items} indicators={false}/>
			</div>
			<div className={classes.content_1}>
				We design IPC standards Printed Circuit Board Schematic and Board Layout. 
				We design component library for new components. We design 
				component library, schematic and board layout with recommended PCB 
			 	design and routing guidelines and deliver with good documentation. 
			</div>
			<div className={classes.content_1}>
				Solutions we deliver 
			</div>
			<div className={classes.list_root}>
			{
				pcb_solutions.map((row, index) =>
				<div 
					id={index} 
					className={(isMobile) ? classes.list_column : classes.list_row}
				>
				{
					row.map((element, index) =>  
						<Paper 
							elevation={1} 
							id={element} 
							className={classes.card}
						>
							<div id={index}className={classes.list_content_1}>
								{element}
							</div>
						</Paper>
					)
				}
				</div>
				)
			}
			</div>
    </div>
  );
}

export default PCBDesign;
