import React from 'react';
import Paper from '@material-ui/core/Paper';
import {isMobile} from "react-device-detect";
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const axios = require('axios');

const styles = theme => ({
	root: {
		padding: theme.spacing(2),
		display: 'flex', 
		flexDirection: 'column',
	},
	contact_title: {
		fontFamily: "Open Sans, Lato, Muli",
		color: '#03234b',
  	letterSpacing: '.015em',
  	fontWeight: 800,
   	fontSize: 'calc(22px + 0.6vw)',
	},
	content_1: {
		padding: (isMobile) ? '3% 0 0 0'  : '2% 0 0 0', 
		color:'#03234b', 
		fontFamily: "Lato, Muli, Open Sans",
		fontWeight: 600,
		fontSize: (isMobile) ? 'calc(10px + 0.5vw)': 'calc(10px + 0.4vw)',
	},
	content_2: {
		color:'#03234b', 
		fontFamily: "Lato, Muli, Open Sans",
		fontWeight: 800,
		fontSize: 'calc(12px + 0.4vw)',
		padding: (isMobile) ? '3% 0 0 0'  : '0 0 0 0',
	},
	form_row: {
		display:'flex',  
		flexDirection: (isMobile) ? 'column' : 'row', 
		width:'100%',
		paddingTop: '2%',
	},
	form_column: {
	 	display:'flex', 
		flexDirection:'column', 
		width:'100%',
		padding: (isMobile) ? '0 2% 0 0' : '0 2% 0 0',
	},
	textbox_content: {
		color:'#03234b', 
		fontFamily: "Lato, Muli, Open Sans",
		fontWeight: 400,
		fontSize: 'calc(12px + 0.3vw)',
	},
	form_row2: {
		display:'flex', 
		flexDirection:'row', 
		alignItems:'center', 
		paddingTop:'2%'
	},
	content_3: {
		color:'#03234b', 
		fontFamily: "Lato, Muli, Open Sans",
		fontWeight: 600,
		fontSize: (isMobile) ? 'calc(10px + 0.5vw)': 'calc(10px + 0.4vw)',
	},
	form_column_2: {
	 	display:'flex', 
		flexDirection:'column', 
		width:'100%',
		padding: (isMobile) ? '0 2% 0 0' : '0 2% 0 2%',
	},
	input: {
		color:'#03234b', 
		fontFamily: "Lato, Muli, Open Sans",
		fontWeight: 600,
		fontSize: (isMobile) ? 'calc(10px + 0.5vw)': 'calc(10px + 0.3vw)',
	},
	button: {
		fontFamily: "Lato, Muli, Open Sans",
		fontWeight: 600,
		margin: (isMobile) ? '7% 0 0 0' : '4% 0 0 0',
		width: (isMobile) ? '100%' : 'calc(160px + 5vw)',
		height: (isMobile) ? 'calc(35px + 5vw)' : 'calc(12px + 2.5vw)',
		fontSize: (isMobile) ?  'calc(14px + 0.4vw)' : 'calc(10px + 0.4vw)',
	},
	dialog_title: {
		color:'#03234b', 
		fontFamily: "Lato, Muli, Open Sans",
		fontWeight: 600,
		fontSize: (isMobile) ? 'calc(10px + 0.5vw)': 'calc(10px + 0.3vw)',
	},
	dialog_content: {
		color:'#03234b', 
		fontFamily: "Lato, Muli, Open Sans",
		fontWeight: 600,
		fontSize: (isMobile) ? 'calc(10px + 0.5vw7': 'calc(10px + 0.3vw)',
	},
});

let textFieldName = ['FULL NAME *', 'COMPANY *', 'COUNTRY *', 'MOBILE *', 
	'EMAIL *', 'APPLICATION *'];
let label = ['name', 'company', 'country', 'mobile', 'email', 'application',];

function SimpleDialog(props) {
  const { onClose, open, message } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" 
    	open={open}
    >
      <DialogTitle id="simple-dialog-title" className={props.dialog_title}>
      	Hello
      </DialogTitle>
      <DialogContent>
	      <DialogContentText 
	      	id="alert-dialog-description" 
	      	className={props.dialog_content}
	      >
		      { message }
	      </DialogContentText>
      </DialogContent>
	    <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>  
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

class ContactUs extends React.Component {
 
 	constructor(props) {
 		super(props);
 		this.state = {
 			selectedFile: [],
 			name: '',
 			company: '', 
 			country: '', 
 			mobile: '',
 			email: '', 
 			application: '', 
			issues: '',
			is_mobile: false,
			pop_up_message: '',
			open_pop_up: false,
 		}
 	}

  onFileChange = event => { 
    this.setState({ selectedFile: event.target.files }); 
  }; 
  
  handleChange = (prop) => (event) => {
    this.setState({[prop]: event.target.value });
  };

  handleClose = (value) => {
    this.setState( {open_pop_up: false} );
  };

  onHandleCheckBoxChange = (event) => {
  	this.setState({is_mobile: event.target.checked});
  }

  sendEmail = () => {
  	let error_message = '';
  	label.forEach(element => {
  		if(this.state[element] == '') {
  			if(error_message.length) {
  				error_message += ',';
  			}
  			error_message += ` ${element}`;
  		}
  	});

  	if(error_message.length) {
  		this.setState({
  			pop_up_message: `Please fill required field ${error_message}`, 
  			open_pop_up: true,
  		});
  	} else {
  		let prop = this.state;
			let formData = new FormData();
			for(let idx = 0; idx < prop.selectedFile.length; idx++){
				formData.append('file[]', prop.selectedFile[idx], 
					prop.selectedFile[idx].name);
			}
			formData.append('name', prop.name);
			formData.append('company', prop.company);
			formData.append('country', prop.country);
			formData.append('mobile', prop.mobile);
			formData.append('email', prop.email);
			formData.append('application', prop.application);
			formData.append('is_mobile', (prop.is_mobile) ? 'Yes' : 'No');
			formData.append('issues', (prop.issues.length) ? prop.issues : '-');

			this.setState({
				pop_up_message: 'Please wait uploading your data...', 
				open_pop_up: true
			});

			/* Send upload data to php mail sender */
			axios.post('http://orbidx.com/MailSender.php', 
	     formData,
        {
          headers: { 'Content-Type': 'multipart/form-data'}
        }
      )
		  .then(response => {
		  	if(response.status == 200) {
		    	this.setState({
						pop_up_message: response.data, 
						open_pop_up: true
					});
		  	} else {
		  		this.setState({
						pop_up_message: 'Sorry Failed to send mail, Please try again', 
						open_pop_up: true
					});
		  	}
		  })
		  .catch(error => {
		  	this.setState({
					pop_up_message: error.message, 
					open_pop_up: true
				});
		  });
		  this.setState({ selectedFile: [] });
  	}
  };

	render() {
		const { classes } = this.props;
		return (
			<div id='orbidx-contactus' className={classes.root}>
				<div className={classes.contact_title}>
						CONTACT US
				</div>
				<div className={classes.content_1}>
					Thank you for your interest in ORBIDX! Please
					fill out the form below. We will contact you shortly.
				</div>
				<div className={ classes.form_row}>
					<div className={ classes.form_column}>
						<div className={classes.content_2}> 
							ENTER YOUR CONTACT INFO
						</div>
						<div className={classes.content_1}>
							The more we know about you, the better we will be at providing you
							with relevant answers.
						</div>

						{
							textFieldName.map((element, index) =>
								<TextField
				          id={element}
				          label={element}
				          style={{ marginTop: (isMobile) ? '10%' : '5%', }}
				          fullWidth
				          margin="dense"
				          color='#03234b'
				          InputLabelProps={{
				            shrink: true,
				            style:{
				            	color:'#03234b', 
											fontFamily: "Lato, Muli, Open Sans",
											fontWeight: 600,
											fontSize: 
												(isMobile) ? 'calc(10px + 0.5vw)': 'calc(10px + 0.4vw)',
										},
				          }}
				          inputProps={{
				          	style:{
				          		color:'#03234b', 
											fontFamily: "Lato, Muli, Open Sans",
											fontWeight: 400,
											fontSize: 
												(isMobile) ? 'calc(10px + 0.5vw)': 'calc(10px + 0.2vw)',
				          	},
				          }}
				          variant="outlined"
				          onChange={this.handleChange(label[index]).bind(this)}
				        />
			        )
			      }
			      <div className={classes.form_row2}>
				      <Checkbox
				        color="primary"
				        style={{paddingLeft: 0}}
				        inputProps={{'aria-label': 'secondary checkbox'}}
				        onChange={this.onHandleCheckBoxChange.bind(this)}
				      />
				      <div className={classes.content_3}>
				      	Please click here if you would like us to contact you in the 
				      	above mentioned #
				      </div>
			      </div>
					</div>
					<Divider orientation="vertical" flexItem />
					<div className={classes.form_column_2}>
						<div className={classes.content_2}>
							PLEASE FILL IN YOUR REQUIREMENTS AND WE WILL GET BACK TO YOU 
							WITHIN 2 WORKING DAYS							
						</div>
						<TextField
		          id="outlined-textarea"
		          label="TECHNICAL INFO"
		          style={{ marginTop: (isMobile) ? '10%' : '5%', }}
		          multiline
		          variant="outlined"
		          rows={10}
		          color='primary'
		          inputProps={{
		            style:{
		            	color:'#03234b', 
									fontFamily: "Lato, Muli, Open Sans",
									fontWeight: 400,
									fontSize: 
										(isMobile) ? 'calc(10px + 0.5vw)': 'calc(10px + 0.4vw)',
		          	},
		          }}
		          InputLabelProps={{
		          	style:{
		            	color:'#03234b', 
									fontFamily: "Lato, Muli, Open Sans",
									fontWeight: 600,
									fontSize: 
										(isMobile) ? 'calc(10px + 0.5vw)': 'calc(10px + 0.4vw)',
		          	},
		          }}
		          onChange={this.handleChange('issues').bind(this)}
		        />
		        <div className={classes.content_1}>
		        	Maximum file size 10MB
		        </div>
		        <div style={{padding: '10px 0 0 0'}}>
			        <input 
			         	className={classes.input} 
			         	type="file" 
			         	multiple 
			         	onChange={this.onFileChange}
			        /> 
		        </div>

					</div>
				</div>
				<Button 
					color="secondary" 
					variant='contained' 
    			className={classes.button}
    			onClick = {this.sendEmail.bind(this)}
    		>
    			SEND YOUR REQUEST 
    		</Button>
    		<SimpleDialog 
    			open={this.state.open_pop_up} 
    			onClose={this.handleClose.bind(this)} 
    			message={this.state.pop_up_message}
    		/>
			</div>
		);
	}
}


export default withStyles(styles)(ContactUs);